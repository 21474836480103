// Actions
import { getCustomerDetail, clearContact } from '../actions/contact';
import { clearPolicies } from '../actions/policy';
import { clearPayNowInfo } from '../actions/payment';
import { clearPolicyDocuments } from '../actions/documents';
import { customerLogout, customerLogoutServer, customerLoginCancel, customerSessionTimeout, expiredOtp,
	 	accountLocked, invalidOTP } from '../actions/auth';;

// Auth Middleware
const authMiddleware = store => next => action => {

	if ((action.type === 'auth/LOGIN_VALIDATE_OTP/rejected' || action.type === 'auth/LOGIN_RESEND_OTP/rejected')
		&& action.payload.httpStatus === 419) {
		next(action);

		// sms otp token process has expired
		store.dispatch(expiredOtp());
	}
	else if (action.type.match(/rejected$/) && action.payload?.httpStatus && action.payload.httpStatus === 401) {
		next(action);
		
		// bearer token has expired
		store.dispatch(customerLogout());

	}
	else if (action.type.match(/rejected$/) && action.payload?.httpStatus && action.payload.httpStatus === 503) {

		// reset auth.loading state
		store.dispatch(customerLoginCancel());

		// API gateway failed to respond, 503 error
		window.location.href = '/503.html';

	}
	else if (action.type === 'auth/SESSION_CHECK') {
		next(action);

		// check if the bearer token has expired
		if (store.getState().auth.bearerTokenExpiresAt - Date.now() <= 0) {
			store.dispatch(customerSessionTimeout());
		}
	}
	else if (action.type === 'auth/LOGIN_VALIDATE_OTP/fulfilled') {
		next(action);

		// get the customer detail and policy summary
		store.dispatch(getCustomerDetail());

	}
	else if (action.type === 'auth/LOGOUT' || action.type === 'auth/SESSION_TIMEOUT') {

		// delete the bearer token on the server
		store.dispatch(customerLogoutServer());

		next(action);

		// empty contacts
		store.dispatch(clearContact());
		
		// empty policy	
		store.dispatch(clearPolicies());

		// empty payment	
		store.dispatch(clearPayNowInfo());

		// empty documents	
		store.dispatch(clearPolicyDocuments());

	}  
	else if (action.type.match(/rejected$/) && action.payload?.httpStatus && action.payload.httpStatus === 403) {
		next(action);
		
		// account is locked
		store.dispatch(accountLocked(store.getState().brand.brandMessages.accountLockMessage));

	}
	else if ((action.type === 'auth/LOGIN_VALIDATE_OTP/rejected')
		&& action.payload.httpStatus === 404) {
		next(action);

		// invalid otp entered
		store.dispatch(invalidOTP(store.getState().brand.brandMessages.invalidOTPMessage));
	}
	else {
		return next(action);
	}

};

// Exports
export default authMiddleware;
