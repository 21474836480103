// Externals
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

// Helpers for fetching async data
import { apiCustomerLogin, apiCustomerLogoutServer, apiValidateOTP, apiResendOTP } from '../async/auth';

// Delete the bearer token on client-side
export const customerLogout = createAction('auth/LOGOUT');

// Delete the bearer token on server-side
export const customerLogoutServer = createAsyncThunk('auth/LOGOUT_SERVER', 
	async (args, { getState, rejectWithValue }) => {
		const token = getState().auth.bearerToken;
		return await apiCustomerLogoutServer(token, rejectWithValue);
	}
);

// Check the session lifetime
export const customerSessionCheck = createAction('auth/SESSION_CHECK');

// The session lifetime was deemed to be exceeded
export const customerSessionTimeout = createAction('auth/SESSION_TIMEOUT');

// Customer Login - Step 1 - Submit mobile number and date of birth
export const customerLogin = createAsyncThunk('auth/LOGIN_CUSTOMER_DETAIL', 
	// TODO: abortController
	async (args, { getState, rejectWithValue }) => {
		return await apiCustomerLogin(args.emailAddress, args.mobileNumber, args.dob, rejectWithValue);
	}
);

export const customerLoginCancel = createAction('auth/LOGIN_CLEAR_CUSTOMER_DETAIL');

export const clearErrorMessage = createAction('auth/CLEAR_AUTH_ERROR');

// Customer Login - Step 2 - Validate the OTP
export const validateOTP = createAsyncThunk('auth/LOGIN_VALIDATE_OTP', 
	// TODO: abortController
	async (otp, { getState, rejectWithValue }) => {
		const customerLoginId = getState().auth.customerLoginId;
		return await apiValidateOTP(otp, customerLoginId, rejectWithValue);
	}
);

export const expiredOtp = createAction('auth/EXPIRED_OTP');

// Customer Login - Resend the OTP
export const resendOTP = createAsyncThunk('auth/LOGIN_RESEND_OTP', 
	// TODO: abortController
	async (args, { getState, rejectWithValue }) => {
		const customerLoginId = getState().auth.customerLoginId;
		return await apiResendOTP(customerLoginId, rejectWithValue);
	}
);

export const accountLocked = createAction('auth/ACCOUNT_LOCKED');

export const invalidOTP = createAction('auth/INVALID_OTP');
