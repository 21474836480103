export const NorthCoverBrandHeadings = {
	myAccountLogin: 'My Account Login',
	myInsurancePolicies: 'My insurance policies',
	policyOverview: 'Policy overview',
	policySummary: 'Policy summary',
	paymentDetails: 'Payment details',
	livesInsured: 'Lives insured details',
	beneficiaryDetails: 'Beneficiary details',
	updateDetails: 'Update details',
	myPaymentDetails: 'My payment details',
	editPaymentDetails: 'Edit payment details',
	myContactDetails: 'My contact details',
	customerContactDetails: 'Customer contact details',
	editContactDetails: 'Edit contact details',
	makePayment: 'Make a payment',
	myDocuments: 'My inbox'
};

export const NorthCoverBrandMessages = {
    changeToPolicyCTA: 'You can update your contact and payment details online. If you would like to make additional changes to your policy, please contact us on <a href="tel:18442038523">1-844-203-8523</a>, ' +
    'between 8am and 8pm (ET) Monday to Friday, and we’ll be happy to help.',
    chequeMethodChangeCTA: 'Due to your current payment method, you will need to contact us on <a href="tel:18442038523">1-844-203-8523</a>, ' +
    'between 8am and 8pm (ET) Monday to Friday, to make changes to your payments details for this policy.',
    ctaContactDetails: 'Update email, postal address and phone number.',
    ctaPaymentDetails: 'Update payment card or bank account details.',
    ctaMakeAPayment: 'Pay overdue payments by credit card.',
    ctaWereHereToHelpTitle: 'We’re here to help',
    ctaWereHereToHelpText: 'Can’t find what you are looking for? Please contact us on <a href="tel:18442038523">1-844-203-8523</a>, between 8am and 8pm (ET) Monday to Friday, and we’ll be happy to help.',
    loginFailureMessage: 'The details you have entered do not match any of our records. Please check the details and try again. Alternatively, you can call us on <a href="tel:18442038523">1-844-203-8523</a> during our operating hours.',
    mobileHelpText: 'This is the cell phone number we have on file for your policy',
    emailHelpText: 'This is the email address we have on file for your policy',
    dobHelpText: 'This is the date of birth of the policyowner as listed on your policy schedule',
    editContactDetailsTerms: 'Any changes made to your contact details will be applied across all of your current insurance policies with North Cover. ' +
    'Please visit the ' +
    '<a href="https://www.northcover.ca/" target="_blank">North Cover website</a> for more information on how to manage your policy.',
    contactNameChangeHelpText: 'To update the contact name on your policy please call us on <a href="tel:18442038523">1-844-203-8523</a>, between 8am and 8pm (ET) Monday to Friday.',
    contactUpdateFailureMessage: 'We were unable to update your contact details. Please try again or call us on <a href="tel:18442038523">1-844-203-8523</a>, between 8am and 8pm (ET) Monday to Friday.',
    paymentUpdateFailureMessage: 'We were unable to update your payment details. Please try again or call us on <a href="tel:18442038523">1-844-203-8523</a>, between 8am and 8pm (ET) Monday to Friday.',
    makePaymentFinaliseMessage: 'If you have any questions regarding your policy please call us on <a href="tel:18442038523">1-844-203-8523</a>, Monday to Friday between 8am and 8pm (ET), and we\'ll be happy to help.',
    makePaymentFailureMessage: 'Please try again or contact our support team on  <a href="tel:18442038523">1-844-203-8523</a>, Monday to Friday between 8am and 8pm (ET), and we\'ll be happy to help.',
    headerText: 'Life and Final Expenses Insurance',
    brandLogoAlt: 'North Cover',
    renewalLabel: 'Policy Anniversary Date',
    footerLinkLabels: ['Privacy Policy', 'Terms & Conditions', 'Accessibility', 'Make a Claim', 'Contact us'],
    footerLinkDestinations: [
        'https://www.northcover.ca/privacy-policy',
        'https://www.northcover.ca/terms-and-conditions',
        'https://www.northcover.ca/accessibility',
        'https://www.northcover.ca/claims',
        'https://www.northcover.ca/contact'
    ],
    footerTextHtml: '<p>North Cover insurance products are underwritten by Teachers Life Insurance Society (Fraternal), distributed by GFSC Life Inc.' + 
    ', Suite 1600, 2 Sheppard Avenue E, North York, ON, M2N 5Y7. North Cover is a trade name registered by GFSC Life Inc.</p>',
    beneficiaryLink: '<a class="edit-link" href="https://www.northcover.ca/documents/north-cover-beneficiary-form.pdf" target="_blank">Beneficiary Form</a>',
    accountLockMessage: 'Your account is currently locked. Please try again in 1 hour. Alternatively, you can call us on <a href="tel:18442038523">1-844-203-8523</a> during our operating hours.',
    invalidOTPMessage: 'Incorrect code entered. Please check and enter the latest code received. Alternatively, you can call us on <a href="tel:18442038523">1-844-203-8523</a> during our operating hours.'
}

export const NorthCoverBrandPhone = '1-844-203-8523';
